import React, {useEffect} from 'react';
import {Redirect} from '@reach/router';
import {useQuery} from '@apollo/client';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import SUBSCRIPTION from 'modules/user/graphql/subscription.graphql';
import WaitingMessage from 'modules/common/WaitingMessage';
// import {useSelector} from 'react-redux';

const Message = () => {
  const {
    data,
    loading,
    networkStatus,
    startPolling,
    stopPolling,
  } = useQuery(SUBSCRIPTION, {fetchPolicy: 'network-only'});

  const subscriptionData = data?.recurrentPayment;

  // const authAndUser = useSelector((state) => state);
  // const cardTokenization = authAndUser?.user?.selectedPlan;
  // const isTrial = cardTokenization?.isTrial;

  useEffect(() => {
    if (subscriptionData?.status === 'CREATED') startPolling(10);
    return () => {
      stopPolling();
    };
  }, [subscriptionData, startPolling, stopPolling]);

  if (loading) return <LoadingIndicator />;

  if (!subscriptionData || subscriptionData?.status === 'CANCELED') {
    return <Redirect noThrow to="/subscription" />;
  }

  if (subscriptionData?.status === 'CREATED') {
    return (
      <WaitingMessage>
        <h3>المرجو الانتظار</h3>
        <h5 className="pt2">
          جاري تأكيد عملية الدفع, نرجو منك الانتظار دون إغلاق هذه الصفحة.
          <br /> قد تستغرق عملية التأكيد مدة ٣ دقائق.
        </h5>
      </WaitingMessage>
    );
  }
  if (subscriptionData?.status === 'ACTIVE') {

    // if (isTrial) return <Redirect noThrow to="/subscription/success-trial" />;

    return <Redirect noThrow to="/subscription/success" />;

  }

  return <Redirect noThrow to="/subscription/failed" />;
};

export default Message;
